const convertToCamelCase = (str: string) => {
  return str.replace(/\_([a-z])/g, (_, char) => char.toUpperCase());
}

const toCamelRecursive = (data: any): any => {
  if (typeof data !== 'object' || data === null) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map(toCamelRecursive);
  }
  const entries = Object.entries(data).map(([key, value]) => {
    return [convertToCamelCase(key), toCamelRecursive(value)];
  });
  return Object.fromEntries(entries);
}

export const toCamel = async (data: Promise<any>) => {
  const rawData = await data;
  return toCamelRecursive(rawData);
}
